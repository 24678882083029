.Register__cont {
  background-image: black; //linear-gradient(230deg, #002cf0 0%, #3e8ade 100%);
  color: rgb(0, 0, 0);
  background-color: rgb(115, 110, 110);

  @media (min-width: 768px) {
    padding: 100px;
  }

  .title_cont {
    font-size: 40px;
    padding: 0 0 16px;
    margin: 16px;
  }
  .row_cont {
    .text_col {
    }
    .form_col {
      form {
        font-weight: 500;
        background-color: #ffffff;
        border-radius: 8px;
        color: #000;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
          rgba(17, 17, 26, 0.1) 0px 8px 24px,
          rgba(17, 17, 26, 0.1) 0px 16px 56px;

        @media (min-width: 320px) {
          padding: 25px;
        }

        @media (min-width: 768px) {
          padding-left: 50px;
          padding-right: 50px;
        }
        .form_header {
          font-size: 25px;
          margin-bottom: 40px;
        }

        input {
          margin-bottom: 40px;
        }

        select {
          margin-bottom: 40px;
        }
      }
    }
  }
}
