.Onboarding__cont {
  background-color: #007bff;
  color: white;
  @media (min-width: 768px) {
    padding: 100px;
  }
  .card {
    background-color: #007bff;

    .card_title {
      margin-bottom: 20px;
    }
  }
}
