#paywell-countdown ul {
  margin-left:  0;
  padding-left:  0;
  margin-bottom: 0;
}

#paywell-countdown ul li {
  display: inline-block;
  list-style-type: none;
  font-weight:  normal;
}

#paywell-countdown ul li span {
  display: block;
  font-size: 1.5rem;
  text-align: center;
  font-weight:  700;
}